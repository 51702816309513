<template>
  <div ref="map" class="map" />
</template>

<style scoped>
.map {
  width: 100%;
  height: 100%;
}
</style>

<script>
import API, { graphqlOperation } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import { listLocations } from "../graphql/queries";
import { onUpdateLocation } from "../graphql/subscriptions";

export default {
  props: ["mini", "visibleCameraSettings", "Cameras", "centerSetting"],
  data() {
    return {
      map: "",
      //mapHeight: 500,
      //mapWidth: 1000,
      nowpos: [],
      locations: [],
      markers: [],
      pos: [],
      before_visibleCameraSettings: [],
      center: {},
    };
  },

  mounted() {},
  methods: {
    CenterControl(controlDiv) {
      // Set CSS for the control border.
      const controlUI = document.createElement("div");
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.border = "2px solid #fff";
      controlUI.style.borderRadius = "3px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.marginTop = "8px";
      controlUI.style.marginBottom = "22px";
      controlUI.style.marginLeft = "8px";
      controlUI.style.marginRight = "8px";
      controlUI.style.textAlign = "settings";
      controlUI.title = "Click to edit Map";
      controlDiv.appendChild(controlUI);
      // Set CSS for the control interior.
      const controlText = document.createElement("div");
      controlText.style.color = "rgb(25,25,25)";
      controlText.style.fontFamily = "Roboto,Arial,sans-serif";
      controlText.style.fontSize = "16px";
      controlText.style.lineHeight = "38px";
      controlText.style.paddingLeft = "5px";
      controlText.style.paddingRight = "5px";
      controlText.innerHTML = "Menu";
      controlUI.appendChild(controlText);
      controlUI.addEventListener("click", () => {
        this.$emit("toggleDrawer");
        console.log("toggleDrawer");
      });
    },

    changeVisibleCamera() {
      for (var markerIndex in this.pos) {
        if (this.visibleCameraSettings[markerIndex].visible) {
          if (this.pos[markerIndex] != null) {
            if (
              this.before_visibleCameraSettings[markerIndex].visible !==
              this.visibleCameraSettings[markerIndex].visible
            ) {
              this.markers[markerIndex] = new window.google.maps.Marker({
                position: {
                  lat: this.pos[markerIndex].lat,
                  lng: this.pos[markerIndex].lng,
                },
                map: this.map,
                label: {
                  text: this.Cameras[markerIndex].deviceName,
                  color: '#000000',
                  fontWeight: 'bold',
                  fontSize: '20px'
                }
              });
              this.nowpos[markerIndex].visible = true;
            }
          }
        } else {
          if (this.pos[markerIndex] != null) {
            if (
              this.before_visibleCameraSettings[markerIndex].visible !==
              this.visibleCameraSettings[markerIndex].visible
            ) {
              this.nowpos[markerIndex].visible = false;
              this.markers[markerIndex].setMap(null);
              this.markers[markerIndex].length = 0;
            }
          }
        }
        this.before_visibleCameraSettings[markerIndex] = JSON.parse(
          JSON.stringify(this.visibleCameraSettings[markerIndex])
        );
        console.log("this.markers", this.markers);
      }

      var visible_pos = [];
      console.log("this.nowpos", this.nowpos);
      for (var nowposIndex in this.nowpos) {
        if (this.nowpos[nowposIndex] != null) {
          if (this.nowpos[nowposIndex].visible === true) {
            visible_pos.push({ lat: this.nowpos[nowposIndex].lat, lng: this.nowpos[nowposIndex].lng });
          }
        }
      }
      if (visible_pos.length > 1) {
        var sw = new window.google.maps.LatLng(
          visible_pos.reduce((a, b) => (a.lat > b.lat ? a : b)).lat,
          visible_pos.reduce((a, b) => (a.lng < b.lng ? a : b)).lng
        );
        var ne = new window.google.maps.LatLng(
          visible_pos.reduce((a, b) => (a.lat < b.lat ? a : b)).lat,
          visible_pos.reduce((a, b) => (a.lng > b.lng ? a : b)).lng
        );

        if (this.centerSetting == "auto") {
          var bounds = new window.google.maps.LatLngBounds(sw, ne);
          this.map.fitBounds(bounds, 10);
        }
      } else {
        console.log("visible_pos", visible_pos);
        if (this.centerSetting == "auto") {
          if (visible_pos[0] != null){
            var center = new window.google.maps.LatLng(visible_pos[0].lat, visible_pos[0].lng);
            this.map.panTo(center);
          }
        }
      }
    },

    async initGetPos() {
      for (var cameraIndex in this.visibleCameraSettings) {
        this.nowpos.push(null);
        console.log(this.visibleCameraSettings[cameraIndex].deviceID);
        var pos_gql = await API.graphql(
          graphqlOperation(listLocations, {
            deviceID: this.visibleCameraSettings[cameraIndex].deviceID
          })
        ).catch((err) => console.error("listDevices", err));
        console.log("pos_gql", pos_gql);
        this.pos.push(pos_gql.data.listLocations.items[0]);

        if (this.pos[cameraIndex] != null) {
          this.nowpos[cameraIndex] = {
            lat: pos_gql.data.listLocations.items[0].lat,
            lng: pos_gql.data.listLocations.items[0].lng,
            visible: true,
          };
        }
      }
      console.log("this.nowpos", this.nowpos);
      console.log("this.pos", this.pos);

      let script = document.createElement("script");
      //APIキーはAmplify Consoleで設定した環境変数から読み込む
      script.src = process.env.VUE_APP_GOOGLE_MAPS_APIKEY;
      script.async = true;
      document.head.appendChild(script);

      window.initMap = () => {
        this.center = new window.google.maps.LatLng(35.0, 135.0);
        this.map = new window.google.maps.Map(this.$refs.map, {
          mapTypeId: "hybrid",
          zoom: 7,
          tilt: 0,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
        });

        var sw_lat;
        var sw_lng;
        var ne_lat;
        var ne_lng;
        for (var nowposIndex in this.nowpos) {
          if (this.nowpos[nowposIndex] != null) {
            if (sw_lat) {
              sw_lat = Math.min(sw_lat, this.nowpos[nowposIndex].lat);
            } else {
              sw_lat = this.nowpos[nowposIndex].lat;
            }

            if (sw_lng) {
              sw_lng = Math.min(sw_lng, this.nowpos[nowposIndex].lng);
            } else {
              sw_lng = this.nowpos[nowposIndex].lng;
            }

            if (ne_lat) {
              ne_lat = Math.max(ne_lat, this.nowpos[nowposIndex].lat);
            } else {
              ne_lat = this.nowpos[nowposIndex].lat;
            }

            if (ne_lng) {
              ne_lng = Math.max(ne_lng, this.nowpos[nowposIndex].lng);
            } else {
              ne_lng = this.nowpos[nowposIndex].lng;
            }
          }
        }

        if (sw_lat && sw_lng && ne_lat && ne_lng) {
          var sw = new window.google.maps.LatLng(sw_lat, sw_lng);
          var ne = new window.google.maps.LatLng(ne_lat, ne_lng);
          var bounds = new window.google.maps.LatLngBounds(sw, ne);
          this.map.fitBounds(bounds, 10);
        } else {
          this.map.panTo(this.center);
        }

        const centerControlDiv = document.createElement("div");
        this.CenterControl(centerControlDiv);
        this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(centerControlDiv);

        for (var markerIndex in this.pos) {
          console.log("this.Cameras[markerIndex]", this.Cameras[markerIndex]);
          this.markers.push(null);
          if (this.visibleCameraSettings[markerIndex].visible) {
            if (this.pos[markerIndex] != null) {
              this.markers[markerIndex] = new window.google.maps.Marker({
                position: {
                  lat: this.nowpos[markerIndex].lat,
                  lng: this.nowpos[markerIndex].lng,
                },
                map: this.map,
                label: {
                  text: this.Cameras[markerIndex].deviceName,
                  color: '#000000',
                  fontWeight: 'bold',
                  fontSize: '20px'
                }
              });
              console.log("this.markers", this.markers);
            }
          }
        }
        console.log("this.visibleCameraSettings", this.visibleCameraSettings);
        console.log("this.before_visibleCameraSettings", this.before_visibleCameraSettings);
      };
      this.before_visibleCameraSettings = JSON.parse(JSON.stringify(this.visibleCameraSettings));
      this.subscribe();
    },

    async subscribe() {
      this.onUpdateLocationSubscription = API.graphql(
        graphqlOperation(onUpdateLocation)
      ).subscribe({
        next: (data) => {
          console.log("data", data);
          console.log("this.pos", this.pos);
          for (var posIndex in this.pos) {
            if (this.pos[posIndex] != null) {
              if (this.pos[posIndex].deviceID === data.value.data.onUpdateLocation.deviceID) {
                this.pos[posIndex] = data.value.data.onUpdateLocation;

                if (this.visibleCameraSettings[posIndex].visible) {
                  this.nowpos[posIndex] = {
                    lat: data.value.data.onUpdateLocation.lat,
                    lng: data.value.data.onUpdateLocation.lng,
                    visible: true,
                  };
                  this.markers[posIndex].setMap(null);
                  this.markers[posIndex] = new window.google.maps.Marker({
                    position: this.nowpos[posIndex],
                    map: this.map,
                    label: { 
                      text: this.Cameras[posIndex].deviceName,
                      color: '#000000',
                      fontWeight: 'bold',
                      fontSize: '20px'
                    }
                  });
                  //this.map.panTo(new window.google.maps.LatLng(this.nowpos[posIndex]));
                } else {
                  this.nowpos[posIndex] = {
                    lat: data.value.data.onUpdateLocation.lat,
                    lng: data.value.data.onUpdateLocation.lng,
                    visible: false,
                  };
                }
              }
            }
          }
          var visible_pos = [];
          for (var nowposIndex in this.nowpos) {
            if (this.nowpos[nowposIndex] != null) {
              if (this.nowpos[nowposIndex].visible === true) {
                visible_pos.push({ lat: this.nowpos[nowposIndex].lat, lng: this.nowpos[nowposIndex].lng });
              }
            }
          }
          if (visible_pos.length > 1) {
            var sw = new window.google.maps.LatLng(
              visible_pos.reduce((a, b) => (a.lat > b.lat ? a : b)).lat,
              visible_pos.reduce((a, b) => (a.lng < b.lng ? a : b)).lng
            );
            var ne = new window.google.maps.LatLng(
              visible_pos.reduce((a, b) => (a.lat < b.lat ? a : b)).lat,
              visible_pos.reduce((a, b) => (a.lng > b.lng ? a : b)).lng
            );
            if (this.centerSetting == "auto") {
              var bounds = new window.google.maps.LatLngBounds(sw, ne);
              this.map.fitBounds(bounds, 10);
            }
          } else {
            if (this.centerSetting == "auto") {
              if (visible_pos[0] != null){
                var center = new window.google.maps.LatLng(visible_pos[0].lat, visible_pos[0].lng);
                this.map.panTo(center);
              }
            }
          }
        },
      });
    },
  },

  async created() {
    this.userName = (await Auth.currentAuthenticatedUser()).username;
  },
  beforeDestroy() {
    this.onUpdateLocationSubscription.unsubscribe();
  },
};
</script>
